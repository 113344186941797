import contactImage from '../img/google-maps.jpg';
import projectsImage from '../img/projects-image.jpg';
import aboutUsImage from '../img/about-us-image.png';

export const OverviewData = [
  {
    image: aboutUsImage,
    title: 'Über uns',
    text: 'Outdoor Solution ist ein leidenschaftliches Garten- und Landschaftsbauunternehmen in Villach. Wir planen & gestalten Gärnten mit Expertise.',
    link: '/aboutus',
  },
  // {
  //   image: productsImage,
  //   title: 'Produkte',
  //   text: 'Unsere Erden können ab sofort bei uns mit Vorreservierung abgeholt werden. Reservieren Sie gleich hier auf unserer Webseite.',
  //   link: '/products',
  // },
  {
    image: projectsImage,
    title: 'Projekte',
    text: 'Unsere Projekte gestalten wir mit Leidenschaft. Individuell auf unsere Kunden angepasst. Hier gelangen Sie zu einer Übersicht bereits realisierter Lösungen.',
    link: '/projects',
  },
  {
    image: contactImage,
    title: 'Kontakt',
    text: 'Nehmen Sie gerne Kontakt mit uns auf, damit wir Ihre Ideen umsetzen können. Wir freuen uns auf Ihren Anruf oder Ihre Nachricht. ',
    link: '#contact',
  },
];
