import styled from 'styled-components';
import { motion } from 'framer-motion';
import { lineAnim } from '../Animation';
import { useScroll } from './useScroll';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '../styles/Colors';

const PanoramaSection = () => {
  const [lineElement, lineControls] = useScroll(0.5);
  const [toggleStates, setToggleStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  return (
    <PanoramaBody>
      <h2>Virtueller Rundgang</h2>
      <motion.div
        className='line'
        animate={lineControls}
        ref={lineElement}
        variants={lineAnim}
      ></motion.div>
      <p>
        Entdecken Sie die Kreativität unserer Landschaftsgärtner in einem
        virtuellen Rundgang!
      </p>
      <div className='frame-wrapper'>
        <iframe
          width='80%'
          height='100%'
          src='https://rundgang.outdoorsolution.at/index.htm'
          frameborder='0'
          allowfullscreen
          allow='xr-spatial-tracking'
        ></iframe>
      </div>
      <div></div>
      <a
        href='https://rundgang.outdoorsolution.at/index.htm'
        target='_blank'
        follow='noreferrer'
        className='more-btn'
      >
        Rundgang öffnen
      </a>
    </PanoramaBody>
  );
};

const PanoramaBody = styled(motion.div)`
  background: white;
  height: auto;
  min-height: 90vh;
  padding-top: 12rem;
  padding-left: 5rem;
  text-align: center;

  p {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .frame-wrapper {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .more-btn {
    margin-bottom: 2rem;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    border: none;
    background: ${Colors.primaryColor};
    padding: 0.7rem 4rem;
    transition: 0.75s ease;
    border-radius: 0.2rem;
    cursor: pointer;
    color: white;

    &:hover {
      transform: scale(1.1);
      transition: 0.75s ease;
    }
  }

  @media (max-width: 800px) {
    padding-left: 0 !important;
    min-height: 30vh;
    .frame-wrapper {
      display: none;
    }
  }
`;

export default PanoramaSection;
